export const routes = {
  entry: "/",
  login: "/login",
  admin: {
    id: "admin",
    path: "/admin",
    regexp: /^\/admin/gm,
    techs: {
      id: "admin/techs",
      path: "/admin/techs",
      regexp: /^\/admin\/techs/gm,
    },
    settings: {
      id: "admin/settings",
      path: "/admin/settings",
      regexp: /^\/admin\/settings/gm,
    },
    passports: {
      id: "admin/passports",
      path: "/admin/passports",
      regexp: /^\/admin\/passports/gm,
    },
    servers: {
      id: "admin/servers",
      path: "/admin/servers",
      regexp: /^\/admin\/servers/gm,
    },
    diagnostic: {
      id: "admin/diagnostic",
      path: "/admin/diagnostic",
      regexp: /^\/admin\/diagnostic/gm,
    },
    access: {
      id: "admin/access",
      path: "/admin/access",
      regexp: /^\/admin\/access/gm,
    },
    autoCleanUp: {
      id: "admin/autoCleanUp",
      path: "/admin/autoCleanUp",
      regexp: /^\/admin\/autoCleanUp/gm,
    },
    lmModels: {
      id: "admin/lmModels",
      path: "/admin/lmModels",
      regexp: /^\/admin\/lmModels/gm,
    },
    lidModels: {
      id: "admin/lidModels",
      path: "/admin/lidModels",
      regexp: /^\/admin\/lidModels/gm,
    },
    nbsCategory: {
      id: "admin/nbsCategory",
      path: "/admin/nbsCategory",
      regexp: /^\/admin\/nbsCategory/gm,
    },
    sources: {
      id: "admin/sources",
      path: "/admin/sources",
      regexp: /^\/admin\/sources/gm,
    },
    theme: {
      id: "admin/theme",
      path: "/admin/theme",
      regexp: /^\/admin\/theme/gm,
    },
    externalApi: {
      id: "admin/externalApi",
      path: "/admin/externalApi",
      regexp: /^\/admin\/externalApi/gm,
    },
    debugMessages: {
      id: "admin/debugMessages",
      path: "/admin/debugMessages",
      regexp: /^\/admin\/debugMessages/gm,
    },
  },
  sources: {
    id: "sources",
    path: "/sources",
    regexp: /^\/sources/gm,
  },
  tasks: {
    id: "tasks",
    path: "/tasks",
    regexp: /^\/tasks/gm,
  },
  results: {
    id: "results",
    path: "/results",
    regexp: /^\/results/gm,
  },
  dictionaries: {
    id: "dictionaries",
    path: "/dictionaries",
    regexp: /^\/dictionaries/gm,
    words: {
      path: "/dictionaries/:dictId/words",
    },
  },
  namespaces: {
    id: "namespaces",
    path: "/namespaces",
    regexp: /^\/namespaces/gm,
  },
  filters: {
    id: "filter",
    path: "/filter",
    regexp: /^\/filter/gm,
  },
  events: {
    id: "events",
    path: "/events",
    regexp: /^\/events/gm,
  },
  statistic: {
    id: "statistic",
    path: "/statistic",
    regexp: /^\/statistic/gm,
  },
  words: {
    id: "words",
    path: "/words",
    regexp: /^\/words/gm,
  },
  speakers: {
    id: "speakers",
    path: "/speakers",
    regexp: /^\/speakers/gm,
  },
  objects: {
    id: "objects",
    path: "/objects",
    regexp: /^\/objects/gm,
  },
  labels: {
    id: "labels",
    path: "/labels",
    regexp: /^\/labels/gm,
  },
  lists: {
    id: "lists",
    path: "/lists",
    regexp: /^\/lists/gm,
  },
  compare: {
    id: "compare",
    path: "/compare",
    regexp: /^\/compare/gm,
  },
  lid: {
    id: "lid",
    path: "/lid",
    regexp: /^\/lid/gm,
  },
  amModels: {
    id: "am-models",
    path: "/am-models",
    regexp: /^\/am-models/gm,
  },
  lmModels: {
    id: "lm-models",
    path: "/lm-models",
    regexp: /^\/lm-models/gm,
  },
  baseStations: {
    id: "base-stations",
    path: "/base-stations",
    regexp: /^\/base-stations/gm,
  },
  codeCity: {
    id: "code-city",
    path: "/code-city",
    regexp: /^\/code-city/gm,
  },
  phoneNumbers: {
    id: "phone-numbers",
    path: "/phone-numbers",
    regexp: /^\/phone-numbers/gm,
  },
  ipAddress: {
    id: "ip-address",
    path: "/ip-address",
    regexp: /^\/ip-address/gm,
  },
  macAddress: {
    id: "mac-address",
    path: "/mac-address",
    regexp: /^\/mac-address/gm,
  },
  map: {
    id: "map",
    path: "/map",
    regexp: /^\/map/gm,
  },
  analytica: {
    id: "analitica",
    path: "/analitica",
    regexp: /^\/analitica/gm,
  },
  graph: {
    id: "graph",
    path: "/graph",
    regexp: /^\/graph/gm,
  },
  wordsCloud: {
    id: "wordsCloud",
    path: "/wordsCloud",
    regexp: /^\/wordsCloud/gm,
  },
  charts: {
    id: "charts",
    path: "/charts",
    regexp: /^\/charts/gm,
  },
};
