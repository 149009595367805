import React, { FC, useEffect, useState } from "react";
import { DetailDiarization as TypeDetailDiarization } from "types/record";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import RecordService from "services/RecordService";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 247px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
  flex: 1,
};

const columnDefs = [
  {
    headerName: "Идентификатор",
    field: "id",
  },
  {
    headerName: "Номер канала",
    field: "channelNumber",
  },
  {
    headerName: "Диктор ID",
    field: "speakerId",
  },
  {
    headerName: "Начало",
    field: "start",
  },
  {
    headerName: "Конец",
    field: "end",
  },
];

interface State {
  loading: boolean;
  rowData: TypeDetailDiarization[];
  error: undefined | Error;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

interface Props {
  recordId: number;
  podrobnoTableHeight: string;
}

const DetailDiarization: FC<Props> = ({ recordId, podrobnoTableHeight }) => {
  const classes = useStyles();
  const [state, setState] = useState<State>(initialState);
  const { rowData } = state;

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    RecordService.getDetailDiarization(recordId)
      .then((res) => {
        setState(() => ({ rowData: res.data, loading: false, error: undefined }));
      })
      .catch((error) => {
        setState(() => ({ rowData: [], loading: false, error }));
      });
  }, [recordId]);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")} style={{ height: podrobnoTableHeight }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="single"
        enableRangeSelection
        suppressCopyRowsToClipboard
        suppressDragLeaveHidesColumns
      />
    </div>
  );
};

export default DetailDiarization;
