import React, { FC } from "react";
import InputDialogWordsTab from "../InputDialogWordsTab";

interface Props {
  value: string;
  onSelection?: Function;
  onClickDelete?: Function;
}

const InputDialogComponent: FC<Props> = ({ value, onSelection, onClickDelete }) => {
  const OnCloseCallback = (text: string, actionType: "save" | "cancel" | "closeOutside") => {
    if (actionType === "save") {
      onSelection?.(text, "editAndClose");
    } else if (actionType === "cancel") {
      onSelection?.(text, "editAndClose");
    } else if (actionType === "closeOutside") {
      // Если диалог закрывается по клику за пределы — вызов удаления фильтра
      onClickDelete?.();
    }

    // Если ничего не выбрано и нажали "ОК" -> вызов удаления фильтра
    if (text === "" || text === `{"strong":[],"vector":[],"phrase":[],"dictionary":[]}`) {
      onClickDelete?.();
    }
  };

  return (
    <div>
      <InputDialogWordsTab onClose={OnCloseCallback} value={value} />
    </div>
  );
};

export default InputDialogComponent;
