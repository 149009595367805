import React, { FC, useCallback, useRef } from "react";
import { Record, RecordDetail, RecordFileViewType } from "types";

// components
import STT from "./STT";
import TextSTT from "./TextTabSTT/TextSTT";
import PlainText from "../PlainText";
import Video from "./Video";
import VideoTextSTT from "./VideoTabSTT/VideoTextSTT";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    width: 200,
  },
  viewTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: theme.spacing(2),
    padding: "2px 8px",
    fontSize: theme.typography.fontSize,
    backgroundColor: "#b7e4ff",
    marginLeft: theme.spacing(1),
  },
  popover: {
    // padding: theme.spacing(2),
    // display: 'flex',
  },
  fg1: {
    flexGrow: 1,
  },
  progress: {
    display: "flex",
    alignItems: "center",
  },
  progressText: {
    marginLeft: 10,
    fontSize: theme.typography.fontSize,
  },
}));

interface Props {
  recordDetail: RecordDetail;
  selectedRecord: Record;
  filterv2: string;
  height: string;
  keywordsHeight: string;
  sttHeight: string;
  viewType: RecordFileViewType;
}

const TabStt: FC<Props> = ({ recordDetail, selectedRecord, filterv2, keywordsHeight, sttHeight, viewType }) => {
  const classes = useStyles();
  const { filter } = recordDetail;

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleSeek = useCallback((time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      videoRef.current.play();
    }
  }, []);

  return (
    <div className={classes.root}>
      {(viewType === RecordFileViewType.AUDIO || viewType === RecordFileViewType.UNKNOWN) && (
        <STT
          viewType={RecordFileViewType.AUDIO}
          queryFilter={filter}
          record={selectedRecord}
          recordDetail={recordDetail}
          filterv2={filterv2}
          keywordsHeight={keywordsHeight}
          sttHeight={sttHeight}
        />
      )}

      {viewType === RecordFileViewType.VIDEO && (
        <div>
          <Video
            record={selectedRecord}
            recordDetail={recordDetail}
            viewType={viewType}
            queryFilter={filter}
            videoRef={videoRef}
          />
          <VideoTextSTT
            viewType={RecordFileViewType.TEXT}
            queryFilter={filter}
            record={selectedRecord}
            recordDetail={recordDetail}
            filterv2={filterv2}
            onWordClick={handleSeek}
          />
        </div>
      )}

      {viewType === RecordFileViewType.TEXT && (
        <TextSTT
          viewType={viewType}
          queryFilter={filter}
          record={selectedRecord}
          recordDetail={recordDetail}
          filterv2={filterv2}
        />
      )}
      {viewType === RecordFileViewType.BITS && <PlainText viewType={viewType} queryFilter={filter} />}
    </div>
  );
};

export default TabStt;
