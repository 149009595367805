import { SET_VIEWED, SET_FAVORITE, SET_RECORDS, RecordsState, RecordsActionTypes } from "../types/viewedRecordTypes";

// Начальное состояние
const initialState: RecordsState = {
  records: [],
};

export const recordsReducer = (state: RecordsState = initialState, action: RecordsActionTypes): RecordsState => {
  switch (action.type) {
    case SET_VIEWED:
      return {
        ...state,
        records: state.records.map((record) =>
          record.id === action.payload.id ? { ...record, isViewed: action.payload.isViewed } : record
        ),
      };
    case SET_FAVORITE:
      return {
        ...state,
        records: state.records.map((record) =>
          record.id === action.payload.id ? { ...record, isFavorite: action.payload.isFavorite } : record
        ),
      };
    case SET_RECORDS:
      return {
        ...state,
        records: action.payload,
      };
    default:
      return state;
  }
};
