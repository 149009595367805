//material
import { IconButton, makeStyles, Paper, Theme } from "@material-ui/core";
import { Pause, PlayArrow } from "@material-ui/icons";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import GetAppIcon from "@material-ui/icons/GetApp";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

import React, { useState, useRef, useEffect, useCallback, FC, MouseEvent } from "react";
import { Keywords, Segment } from "types";
import LoadingAudio from "./LoadingAudio";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import SuperPlayerSettings from "components/audioPlayer/components/SuperPlayerSettings";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

const useStyles = makeStyles((theme: Theme) => ({
  channelsContainer: {
    display: "flex",
    gap: 3,
    alignItems: "center",
    padding: "0px 13px",
  },
  channelButton: {
    width: 18,
    height: 36,
    cursor: "pointer",
  },
  leftChannel: {
    marginRight: 1,
    borderRadius: "100% 0px 0px 100% / 50% 0px 0px 50%",
  },
  rightChannel: {
    marginLeft: 1,
    borderRadius: "0px 100% 100% 0px / 0px 50% 50% 0px",
  },
  activeChannel: {
    backgroundColor: theme.palette.primary.main,
  },
  inactiveChannel: {
    backgroundColor: "#ccc",
  },
  leftHeadsetImage: {
    width: 32,
    height: 32,
    marginLeft: -12,
  },
  rightHeadsetImage: {
    width: 32,
    height: 32,
  },
}));

interface AudioPlayerProps {
  audioUrl: string;

  markers: Keywords[];
  fileName: string;
  segments?: Segment[];
  isExpanded: boolean;
  setIsExpanded: Function;
  channelNumber: number;
}

const SuperPlayer: FC<AudioPlayerProps> = ({
  audioUrl,
  markers,
  fileName,
  isExpanded,
  setIsExpanded,
  channelNumber,
}) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const waveformData = useRef<any[]>([]);

  const [isAudioLoaded, setIsAudioLoaded] = useState(false);

  const [activeChannels, setActiveChannels] = useState({ left: true, right: true });
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<MediaElementAudioSourceNode | null>(null);

  const [isLooping, setIsLooping] = useState(false);

  //Вейвформа рисуется
  const [isDrawing, setIsDrawing] = useState(true);
  // Анимация зума.
  const [isAnimating, setIsAnimating] = useState(false);
  //синхронизация
  const { sttSyncTextAndPlayer } = useSelector((state: RootState) => state.settings);
  const animationFrameRef = useRef<number>(0);

  const updateCurrentTime = useCallback(() => {
    if (!audioRef.current || !sttSyncTextAndPlayer) return;

    // setCurrentTime(audioRef.current.currentTime);
    if (sttSyncTextAndPlayer) {
      currTimeSubscriber.next(audioRef.current.currentTime); // Отправка времени

      animationFrameRef.current = requestAnimationFrame(updateCurrentTime); // Рекурсивный вызов
    }
  }, [sttSyncTextAndPlayer]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setZoomLevel(1); // Сбросить зум, когда сворачиваем
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  //в колбэк не оборачивать!
  const generateWaveformData = async (audioElement: HTMLAudioElement) => {
    // console.log("generateWaveformData вызвана с audioElement:", audioElement);
    if (!audioElement) {
      // console.log("audioElement не найден");
      return;
    }

    // console.log("Начало генерации вейвформы");

    try {
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const response = await fetch(audioUrl);
      // console.log("Аудио успешно загружено через fetch");

      const arrayBuffer = await response.arrayBuffer();
      // console.log("ArrayBuffer получен");

      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      // console.log("Аудиобуфер успешно декодирован");

      const samples = 10000;
      waveformData.current = [];

      for (let channel = 0; channel < channelNumber; channel++) {
        const rawData = audioBuffer.getChannelData(channel);
        const blockSize = Math.floor(rawData.length / samples);
        const filteredData = [];
        for (let i = 0; i < samples; i++) {
          let blockStart = blockSize * i;
          let sum = 0;
          for (let j = 0; j < blockSize; j++) {
            sum += Math.abs(rawData[blockStart + j]);
          }
          filteredData.push(sum / blockSize);
        }

        const multiplier = Math.pow(Math.max(...filteredData), -1);
        waveformData.current.push(filteredData.map((n) => n * multiplier));
      }

      // console.log("Вейвформа сгенерирована");

      drawWaveform(() => setIsDrawing(false));
    } catch (error) {
      console.error("Ошибка при генерации вейвформы", error);
      setIsDrawing(false);
    }
  };

  const drawWaveform = useCallback(
    (onComplete: () => void) => {
      if (!canvasRef.current || waveformData.current.length === 0) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d")!;
      setIsDrawing(true);
      const dpr = window.devicePixelRatio || 1;
      const padding = 5;
      // console.log("Рисуем вейвформу"); // Лог для отслеживания

      canvas.width = canvas.offsetWidth * dpr;
      canvas.height = (isExpanded ? 300 : 80) * dpr;

      ctx.scale(dpr, dpr);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const timelineHeight = 20;
      const width = canvas.width / dpr;
      const height = canvas.height / dpr;
      const barWidth = (width / waveformData.current[0].length) * zoomLevel;
      const visibleBars = Math.floor(width / barWidth);
      const startIndex = Math.floor((currentTime / duration) * (waveformData.current[0].length - visibleBars));

      // Функция для выбора подходящего временного шага
      const getTimeStep = (visibleDuration: number): number => {
        const steps = [1, 2, 5, 10, 15, 30, 60, 120, 300, 600]; // в секундах
        for (let step of steps) {
          if (visibleDuration / step <= 10) {
            return step;
          }
        }
        return steps[steps.length - 1];
      };

      // Используйте getTimeStep вместо Math.pow
      const drawTimeline = (yPosition: number) => {
        const visibleDuration = (visibleBars / waveformData.current[0].length) * duration;
        const startTime = (startIndex / waveformData.current[0].length) * duration;
        const endTime = startTime + visibleDuration;
        const timeStep = getTimeStep(visibleDuration);

        ctx.fillStyle = "#000";
        ctx.font = "10px Arial";
        ctx.textAlign = "center";

        for (let time = Math.ceil(startTime / timeStep) * timeStep; time <= endTime; time += timeStep) {
          const x = ((time - startTime) / visibleDuration) * width;
          ctx.beginPath();
          //добавил тут x + 2 везде, чтобы не обрезало ноль. Возможно стоит сделать так только для нулевой и последней секунды потом.
          ctx.moveTo(x + 2, yPosition);
          ctx.lineTo(x + 2, yPosition + 5);
          ctx.stroke();
          ctx.fillText(
            time >= 60 ? `${Math.floor(time / 60)}:${(time % 60).toFixed(0).padStart(2, "0")}` : time.toFixed(0),
            x + 2,
            yPosition + 15
          );
        }
      };

      // Функция для отрисовки маркеров ключевых слов
      // Нужно добавить будет логику, когда ключевые слова находятся рядом, чтобы они нормально отображались.
      const drawMarkers = () => {
        // console.log("Рисуем слова");
        markers.forEach((keyword) => {
          const { color, words } = keyword; // Извлекаем color и words из Keywords

          words.forEach((word) => {
            const { start } = word; // Извлекаем start из Word
            if (start < 0 || start > duration) return;

            const markerPosition =
              ((start - (startIndex / waveformData.current[0].length) * duration) /
                ((visibleBars / waveformData.current[0].length) * duration)) *
              width;

            if (markerPosition >= 0 && markerPosition <= width) {
              // Отрисовка линии маркера
              ctx.beginPath();
              ctx.strokeStyle = color; // Используем color из Keywords
              ctx.lineWidth = 2;
              ctx.moveTo(markerPosition, 0);
              ctx.lineTo(markerPosition, height);
              ctx.stroke();

              // Отрисовка слова-лейбла
              ctx.fillStyle = color; // Используем color из Keywords
              ctx.font = "12px Arial";
              ctx.textAlign = "start";
              ctx.fillText(word.word, markerPosition + 5, 15); // Используем word из Word

              // Тут сделать лейбл, чтобы можно было навести на слово и была доп инфа?
            }
          });
        });
      };

      if (isExpanded && channelNumber === 2) {
        // Отрисовка двух каналов
        for (let channel = 0; channel < 2; channel++) {
          const channelHeight = (height - timelineHeight) / 2;
          const yOffset = channel * channelHeight + timelineHeight;

          ctx.fillStyle = channel === 0 ? "#b7e1dd" : "#e1b7dd";
          ctx.strokeStyle = channel === 0 ? "#b7e1dd" : "#e1b7dd";

          for (let i = 0; i < visibleBars; i++) {
            const dataIndex = startIndex + i;
            if (dataIndex >= waveformData.current[channel].length) break;

            const x = i * barWidth;
            const barHeight = waveformData.current[channel][dataIndex] * (channelHeight - padding * 2);

            ctx.beginPath();
            ctx.lineTo(x + barWidth / 2, yOffset + channelHeight / 2 + padding - barHeight / 2);
            ctx.lineTo(x + barWidth / 2, yOffset + channelHeight / 2 + padding + barHeight / 2);
            ctx.stroke();
          }

          // Прогресс для каждого канала
          const progressWidth = (currentTime / duration) * width;
          ctx.fillStyle = channel === 0 ? "#357F78" : "#7F3578";
          ctx.strokeStyle = channel === 0 ? "#357F78" : "#7F3578";

          ctx.save();
          ctx.beginPath();
          ctx.rect(0, yOffset, progressWidth, channelHeight);
          ctx.clip();

          for (let i = 0; i < visibleBars; i++) {
            const dataIndex = startIndex + i;
            if (dataIndex >= waveformData.current[channel].length) break;

            const x = i * barWidth;
            const barHeight = waveformData.current[channel][dataIndex] * (channelHeight - padding * 2);

            ctx.beginPath();
            ctx.lineTo(x + barWidth / 2, yOffset + channelHeight / 2 + padding - barHeight / 2);
            ctx.lineTo(x + barWidth / 2, yOffset + channelHeight / 2 + padding + barHeight / 2);
            ctx.stroke();
          }

          ctx.restore();

          // Вертикальный бегунок
          ctx.beginPath();
          ctx.strokeStyle = "#357F78";
          ctx.lineWidth = 1;
          ctx.moveTo(progressWidth, 0);
          ctx.lineTo(progressWidth, height);
          ctx.stroke();

          // Отрисовка timeline для каждого канала
          drawTimeline(channelHeight * 2 + 5);
        }
      } else {
        // Отрисовка общей дорожки (для компактного вида)
        ctx.fillStyle = "#b7e1dd";
        ctx.strokeStyle = "#b7e1dd";
        const waveformHeight = height - timelineHeight;

        for (let i = 0; i < visibleBars; i++) {
          const dataIndex = startIndex + i;
          if (dataIndex >= waveformData.current[0].length) break;

          const x = i * barWidth;
          const barHeight = waveformData.current[0][dataIndex] * (height - padding * 2);

          ctx.beginPath();
          ctx.lineTo(x + barWidth / 2, height / 2 + padding - barHeight / 2);
          ctx.lineTo(x + barWidth / 2, height / 2 + padding + barHeight / 2);
          ctx.stroke();
        }

        // Прогресс
        const progressWidth = (currentTime / duration) * width;
        ctx.fillStyle = "#357F78";
        ctx.strokeStyle = "#357F78";

        ctx.save();
        ctx.beginPath();
        ctx.rect(0, 0, progressWidth, height);
        ctx.clip();

        for (let i = 0; i < visibleBars; i++) {
          const dataIndex = startIndex + i;
          if (dataIndex >= waveformData.current[0].length) break;

          const x = i * barWidth;
          const barHeight = waveformData.current[0][dataIndex] * (height - padding * 2);

          ctx.beginPath();
          ctx.lineTo(x + barWidth / 2, height / 2 + padding - barHeight / 2);
          ctx.lineTo(x + barWidth / 2, height / 2 + padding + barHeight / 2);
          ctx.stroke();
        }

        ctx.restore();

        // Вертикальный бегунок
        ctx.beginPath();
        ctx.strokeStyle = "#357F78";
        ctx.lineWidth = 1;
        ctx.moveTo(progressWidth, 0);
        ctx.lineTo(progressWidth, height);
        ctx.stroke();

        // Отрисовка timeline
        drawTimeline(waveformHeight);
      }

      // Отрисовка маркеров
      drawMarkers();
      onComplete();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExpanded, currentTime, zoomLevel, duration, channelNumber]
  );

  const handleCanvasClick = (e: MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas || !audioRef.current) return;

    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;

    // Так как у нас есть зум, чтобы корректно перейти на нужный участок,
    // необходимо просчитывать какая часть канвы отображается при данном зуме.
    // Рассчитываем ширину видимой части
    const dpr = window.devicePixelRatio || 1;
    const width = canvas.width / dpr;
    const barWidth = (width / waveformData.current[0].length) * zoomLevel;
    const visibleBars = Math.floor(width / barWidth);

    // Используем currentTime и duration для определения startIndex
    const startIndex = Math.floor((currentTime / duration) * (waveformData.current[0].length - visibleBars));

    // Определяем индекс клика с учетом смещения и масштаба
    const clickIndex = startIndex + Math.floor((x / width) * visibleBars);

    // Вычисляем время, соответствующее индексу клика
    const clickTime = (clickIndex / waveformData.current[0].length) * duration;

    // Устанавливаем новое время воспроизведения
    audioRef.current.currentTime = clickTime;
    setCurrentTime(clickTime);

    // Добавляем переотрисовку?
    // drawWaveform(() => setIsDrawing(false));
    // Добавляем подписку, чтобы в стт жёлтым подсвечивалось
    currTimeSubscriber.next(clickTime);
  };

  // Анимация для зума
  const smoothZoom = (targetZoom: number, duration: number = 300) => {
    // Проверяем, активна ли анимация, и если да, то просто возвращаемся
    if (isAnimating) return;

    setIsAnimating(true); // Устанавливаем флаг анимации в true

    const startZoom = zoomLevel;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeProgress = progress * (2 - progress);

      const newZoom = startZoom + (targetZoom - startZoom) * easeProgress;
      setZoomLevel(newZoom);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setIsAnimating(false); // Сбрасываем флаг анимации после завершения
      }
    };

    requestAnimationFrame(animate);
  };

  const zoomIn = () => {
    const newZoom = Math.min(zoomLevel * 2, 64);
    smoothZoom(newZoom);
  };

  const zoomOut = () => {
    const newZoom = Math.max(zoomLevel / 2, 1);
    smoothZoom(newZoom);
  };

  //плей, пауза
  const togglePlay = () => {
    if (audioRef.current?.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      if (sttSyncTextAndPlayer) {
        animationFrameRef.current = requestAnimationFrame(updateCurrentTime); // Запуск обновления времени
      }
    } else {
      audioRef.current?.pause();
      setIsPlaying(false);
      cancelAnimationFrame(animationFrameRef.current); // Остановка обновления времени
    }
  };

  //+3 сек, -3 сек
  const seekBackward = () => {
    if (audioRef.current) {
      const newTime = Math.max(audioRef.current.currentTime - 3, 0);
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
      animationFrameRef.current = requestAnimationFrame(updateCurrentTime); // Запуск обновления времени
      drawWaveform(() => setIsDrawing(false));
    }
  };

  const seekForward = () => {
    if (audioRef.current) {
      const newTime = Math.min(audioRef.current.currentTime + 3, duration);
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
      animationFrameRef.current = requestAnimationFrame(updateCurrentTime); // Запуск обновления времени
      drawWaveform(() => setIsDrawing(false));
    }
  };

  //левый и правый каналы
  const initializeAudioContext = () => {
    if (!audioContextRef.current && audioRef.current) {
      const context = new AudioContext();
      audioContextRef.current = context;
      sourceRef.current = context.createMediaElementSource(audioRef.current);
    }
  };

  const updateChannels = () => {
    initializeAudioContext();

    const audio = audioRef.current;
    const context = audioContextRef.current;
    if (audio && context && sourceRef.current) {
      const splitter = context.createChannelSplitter(2);
      const merger = context.createChannelMerger(2);

      const leftGain = context.createGain();
      const rightGain = context.createGain();

      leftGain.gain.value = activeChannels.left ? 1 : 0;
      rightGain.gain.value = activeChannels.right ? 1 : 0;

      sourceRef.current.disconnect();
      sourceRef.current.connect(splitter);
      splitter.connect(leftGain, 0);
      splitter.connect(rightGain, 1);

      leftGain.connect(merger, 0, 0);
      rightGain.connect(merger, 0, 1);

      merger.connect(context.destination);
    }
  };

  const toggleChannel = (channel: "left" | "right") => {
    setActiveChannels((prev) => {
      const newState = { ...prev, [channel]: !prev[channel] };

      // Если оба канала неактивны, активируем оба
      if (!newState.left && !newState.right) {
        return { left: true, right: true };
      }

      return newState;
    });
  };

  //зацикленное воспроизведение
  const toggleLoop = () => {
    setIsLooping(!isLooping);
    if (audioRef.current) {
      audioRef.current.loop = !isLooping;
    }
  };

  //Скачать файл
  const download = useCallback(() => {
    fetch(audioUrl).then((res) => {
      res.blob().then((blob) => {
        let name = fileName;
        if (!name.endsWith(".wav")) {
          name += ".wav";
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.loop = isLooping;
    }
  }, [isLooping]);

  useEffect(() => {
    updateChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannels]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // console.log("Аудио элемент доступен, добавляем обработчики событий");
      const handleLoadedMetadata = () => {
        setDuration(audioElement.duration);
        setIsAudioLoaded(true);
        // console.log("Аудио загружено, продолжительность:", audioElement.duration);
      };
      //длительность файла
      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
      };

      audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [audioUrl, markers]);

  useEffect(() => {
    if (isAudioLoaded && audioRef.current) {
      generateWaveformData(audioRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioLoaded, markers, audioUrl]);

  useEffect(() => {
    if (isAudioLoaded && waveformData.current.length > 0) {
      // console.log("Вейвформа готова, рисуем её");
      drawWaveform(() => setIsDrawing(false));
    }
  }, [isAudioLoaded, isExpanded, currentTime, zoomLevel, channelNumber, drawWaveform, markers, audioUrl]);

  // подписка на клик по слову.
  useEffect(() => {
    currTimeSubscriber.subscribe((time) => {
      if (!audioRef.current) return;
      if (audioRef.current.currentTime === time) return;
      audioRef.current.currentTime = time;
    });
  }, []);

  useEffect(() => {
    if (sttSyncTextAndPlayer && audioRef.current) {
      animationFrameRef.current = requestAnimationFrame(updateCurrentTime); // Запуск обновления времени
    } else {
      cancelAnimationFrame(animationFrameRef.current); // Остановка обновления времени
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sttSyncTextAndPlayer]);

  return (
    <Paper
      elevation={3}
      style={{
        overflow: isExpanded ? "auto" : "hidden",
        height: isExpanded ? "320px" : "100px",
        transition: "height 0.3s ease, left 0.3s ease",
        margin: "0px -16px",
      }}
      className="full-width-element"
    >
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div id="первая часть кнопок" style={{ display: "flex" }}>
            {/* Настройки */}
            <SuperPlayerSettings />
            {/* <IconButton title="Настройки" disableRipple color="primary" size="medium">
              <SettingsIcon fontSize="large" />
            </IconButton> */}
            {/* Наушники по условию */}
            <div className={classes.channelsContainer}>
              <div
                title="Левый канал"
                onClick={() => toggleChannel("left")}
                className={`${classes.channelButton} ${classes.leftChannel} ${
                  activeChannels.left ? classes.activeChannel : classes.inactiveChannel
                }`}
              >
                <img src="/img/left-headset.png" alt="left headset" className={classes.leftHeadsetImage} />
              </div>
              <div
                title="Правый канал"
                onClick={() => toggleChannel("right")}
                className={`${classes.channelButton} ${classes.rightChannel} ${
                  activeChannels.right ? classes.activeChannel : classes.inactiveChannel
                }`}
              >
                <img src="/img/right-headset.png" alt="right headset" className={classes.rightHeadsetImage} />
              </div>
            </div>
            {/* -3секунды, плей, +3 секунды или просто плей */}
            {isExpanded ? (
              <>
                <IconButton disableRipple color="primary" title="Текущая позиция - 3 сек." onClick={seekBackward}>
                  <RotateLeftIcon fontSize="large" />
                </IconButton>
                <IconButton color="primary" size="medium" onClick={togglePlay}>
                  {isPlaying ? <Pause style={{ fontSize: "3rem" }} /> : <PlayArrow style={{ fontSize: "3rem" }} />}
                </IconButton>
                <IconButton disableRipple color="primary" title="Текущая позиция + 3 сек." onClick={seekForward}>
                  <RotateRightIcon fontSize="large" />
                </IconButton>
              </>
            ) : (
              <IconButton color="primary" size="medium" onClick={togglePlay}>
                {isPlaying ? <Pause style={{ fontSize: "3rem" }} /> : <PlayArrow style={{ fontSize: "3rem" }} />}
              </IconButton>
            )}
            {/* Зум по условию */}
            {isExpanded && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" size="medium" title="Увеличить масштаб" onClick={zoomIn}>
                  <ZoomInIcon fontSize="large" />
                </IconButton>
                <span>{zoomLevel.toFixed(2)}x</span>
                <IconButton color="primary" size="medium" title="Уменьшить масштаб" onClick={zoomOut}>
                  <ZoomOutIcon fontSize="large" />
                </IconButton>
              </div>
            )}
            {/* зациклено по условию */}
            {isExpanded && (
              <IconButton
                title="Зацикленное воспроизведение"
                disableRipple
                color={isLooping ? "primary" : "default"}
                onClick={toggleLoop}
              >
                <AllInclusiveIcon fontSize="large" />
              </IconButton>
            )}
            {/* Скачать декодированный файл */}
            <IconButton disableRipple color="primary" title="Скачать декодированный файл" onClick={download}>
              <GetAppIcon fontSize="large" />
            </IconButton>
          </div>

          <div style={{ display: "flex", justifyContent: "center", width: "90%" }}>
            {isDrawing && <LoadingAudio />}
            <audio ref={audioRef} src={audioUrl} crossOrigin="anonymous" />
            <canvas
              ref={canvasRef}
              onClick={handleCanvasClick}
              style={{
                width: "100%",
                height: isExpanded ? "300px" : "80px",
                cursor: "pointer",
              }}
              // onMouseMove={handleMouseMove}
            />
          </div>
          <div id="время и коллапс" style={{ display: "flex" }}>
            {/* Время */}
            <div style={{ display: "flex", alignItems: "center", width: "100px", justifyContent: "center" }}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            {/* Свернуть/Развернуть */}
            <IconButton color="primary" size="medium" onClick={toggleExpand} disabled={isDrawing}>
              {isExpanded ? <ExpandMoreIcon fontSize="large" /> : <ExpandLessIcon fontSize="large" />}
            </IconButton>
          </div>
        </div>
      </>
      {/* )} */}
    </Paper>
  );
};

export default SuperPlayer;
