import React, { FC, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Records from "./records/Records";
import { AdminTypeFilter } from "types/admin";
import { RecordDetail, Record, RecordFileViewType } from "types";
import { Group } from "types/queryBuilder";
import useQueryString from "hooks/useQueryString";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import AccessService from "services/admin/AccessService";
import { useDispatch } from "react-redux";
import {
  setRemoveRecordsAccess,
  setShowAutoInformatorsName,
  setShowNameSpaceName,
  setShowSpeakersName,
} from "redux/actions/accessActions";
import { generateUuid } from "functions/common";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import ResultDetail from "./resultDetail/ResultDetail";
import Search from "components/search/Search";
import SuperPlayerWrapper from "./SuperPlayerWrapper";
import ViewTypeButton from "./resultDetail/ViewTypeButton";

// Стили для компонентов
const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(-80px + 100vh)",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  search: {
    paddingBottom: "5px",
    display: "flex",
  },
  splitWrapper: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    position: "relative",
    transition: "height 0.3s ease",
  },
  records: {
    flex: 1,
    overflowY: "auto",
    transition: "height 0.3s ease",
  },
  detail: {
    overflowY: "auto",
    transition: "height 0.3s ease",
  },
  superPlayerWrapper: {
    transition: "height 0.3s ease, left 0.3s ease",
  },
  ratioButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  ratioBorderColor: {
    borderColor: theme.palette.primary.main,
  },
}));

interface Props {
  filterString: string;
  availableFilters: AdminTypeFilter[];
}

const NewResults: FC<Props> = ({ filterString, availableFilters }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const history = useHistory();

  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const [filter, setFilter] = useState<Group>(defaultFilter);

  const [recordDetail, setRecordDetail] = useState<RecordDetail | undefined>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<Record | undefined>(undefined);

  const [basis, setBasis] = useState("50%");

  // Функция для расчета высоты блоков и таблиц, внутри блока результатов.
  // Так как плеер имеет 3 состояния: не отображается, маленький, развёрнутый
  // Просчитываю высоту для всех блоков здесь в одном месте, чтобы не менять потом
  // в каждом компоненте по свойству isExpanded

  const calculateHeights = () => {
    // когда компактная версия плеера
    //для всего блока результатов
    const baseHeight = "calc(100vh - 241px)";
    // для таблицы рекордов
    const baseTableHeight = "calc(100vh - 330px)";
    //для ключевых слов
    const baseTableKeywordsHeight = "calc(-380px + 100vh)";
    // для вкладки "Подробно"
    const basePodrobnoTable = "calc(100vh - 386px)";

    // когда запись не выбрана и плеер не отображается
    //для всего блока результатов
    const noPlayerHeight = "calc(-135px + 100vh)";
    // для таблицы рекордов
    const noPlayerTableHeight = "calc(100vh - 230px)";

    // условие и размер при развёрнутом плеере
    // Значение 'calc...' - это размер при развёрнутом виде плеера
    const recordsHeight = selectedRecord ? (isExpanded ? "calc(100vh - 461px)" : baseHeight) : noPlayerHeight;

    const recordTableHeight = selectedRecord
      ? isExpanded
        ? "calc(100vh - 550px)"
        : baseTableHeight
      : noPlayerTableHeight;

    const keywordsTableHeight = selectedRecord
      ? isExpanded
        ? "calc(-600px + 100vh)"
        : baseTableKeywordsHeight
      : noPlayerTableHeight;

    const podrobnoTableHeight = selectedRecord
      ? isExpanded
        ? "calc(100vh - 606px)"
        : basePodrobnoTable
      : noPlayerTableHeight;

    return { recordsHeight, recordTableHeight, keywordsTableHeight, podrobnoTableHeight };
  };

  // Получаем высоты
  const { recordsHeight, recordTableHeight, keywordsTableHeight, podrobnoTableHeight } = calculateHeights();

  // запустит поиск по фильтру
  const handleSearch = (filter: Group) => {
    history.push(routes.results.path + "?f=" + JSON.stringify(filter));
  };

  // Определяем возможные состояния
  const ratios = ["0%", "20%", "50%", "80%", "100%"];
  const ratioTexts = ["3:1", "2:1", "1:1", "1:2", "1:3"];

  // Начальный индекст (начальное соотношение)
  const [currentIndex, setCurrentIndex] = useState(2);

  // Функция для циклического изменения индекса
  const cycleRatio = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ratios.length);
  };

  // Текущее значение базиса и текст кнопки
  const currentText = ratioTexts[currentIndex];

  // Для изменения типа отображеия

  const [currentViewType, setCurrentViewType] = useState<RecordFileViewType>(RecordFileViewType.AUDIO);

  const handleViewTypeChanged = (newViewType: RecordFileViewType) => {
    setCurrentViewType(newViewType);
    console.log("Новый ViewType:", newViewType);
  };

  useEffect(() => {
    if (recordDetail) {
      setCurrentViewType(recordDetail.viewType);
    }
  }, [recordDetail]);

  useEffect(() => {
    try {
      const filter: Group = JSON.parse(filterString);
      setFilter(filter);
    } catch (e) {}
  }, [filterString]);

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <div className={classes.ratioButtons}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            title="Изменить соотношение блоков"
            //не хотел contained, и хотел супер маленькую кнопочку
            style={{ minWidth: "40px", color: "rgba(0, 0, 0, 0.87)" }}
            onClick={() => {
              cycleRatio();
              setBasis(ratios[(currentIndex + 1) % ratios.length]);
            }}
          >
            {currentText}
          </Button>
          <ViewTypeButton onViewTypeChange={handleViewTypeChanged} currentViewType={currentViewType} />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Search
            filterString={filterString}
            handleSearch={handleSearch}
            availableFilters={availableFilters}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      </div>

      <div className={classes.splitWrapper}>
        <div className={classes.records} style={{ height: recordsHeight, flex: `3 3 ${basis}` }}>
          <Records
            setFilter={setFilter}
            availableFilters={availableFilters}
            filterString={filterString}
            recordDetail={recordDetail}
            setRecordDetail={setRecordDetail}
            setSelectedRecord={setSelectedRecord}
            height={recordsHeight}
            tableHeight={recordTableHeight}
          />
        </div>
        <div className={classes.detail} style={{ height: recordsHeight, flex: `1 1 ${basis}` }}>
          <ResultDetail
            recordDetail={recordDetail}
            selectedRecord={selectedRecord}
            filterString={filterString}
            height={recordsHeight}
            sttHeight={recordTableHeight}
            keywordsHeight={keywordsTableHeight}
            podrobnoTableHeight={podrobnoTableHeight}
            viewType={currentViewType}
          />
        </div>
      </div>

      {selectedRecord && recordDetail && (
        <SuperPlayerWrapper
          recordDetail={recordDetail}
          filterv2={filterString}
          record={selectedRecord}
          expand={isExpanded}
          setExpand={setIsExpanded}
          // viewType={currentViewType}
        />
      )}
    </div>
  );
};

// получть query параметры
const GetQueryParams: FC<{ availableFilters: AdminTypeFilter[] }> = ({ availableFilters }) => {
  const [filterString, setFilterString] = useState<undefined | string>(undefined);
  const queryParams = useQueryString();

  // проверка на наличие query параметра f
  useEffect(() => {
    const filterString = queryParams.get("f");

    if (filterString !== null) {
      setFilterString(filterString);
    }
    if (filterString === null) {
      setFilterString("");
    }
  }, [queryParams]);

  if (filterString === undefined) {
    return null;
  }
  return <NewResults filterString={filterString} availableFilters={availableFilters} />;
};

const GetAvailableFilters: FC = () => {
  const dispatch = useDispatch();
  const { filters, loading } = useAvailableFilters("records");

  useEffect(() => {
    AccessService.getUserAccessRemoveRecords().then(({ data }) => {
      dispatch(setRemoveRecordsAccess(data.isCanDelete));
      dispatch(setShowNameSpaceName(data.isShowNameSpaceName));
      dispatch(setShowSpeakersName(data.isShowSpeakersName));
      dispatch(setShowAutoInformatorsName(data.isShowAutoInformatorsName));
    });
  }, [dispatch]);

  if (loading) {
    return null;
  }
  return <GetQueryParams availableFilters={filters} />;
};

export default GetAvailableFilters;
