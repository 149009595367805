import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import RecordService from "services/RecordService";
import clsx from "clsx";
import { routes } from "routes";
import { useHistory } from "react-router-dom";
import { generateUuid } from "functions/common";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import NoData from "components/NoData";
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  root: {
    padding: 5,
  },
  iframe: {
    width: "100%",
    border: "none",
    height: "calc(100vh - 160px)",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 100px)",
  },
  actions: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  filter: {
    flexGrow: 1,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
  button: {
    height: 40,
  },
  mr10: {
    marginRight: 10,
  },
}));

const Iframe = ({ src }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const history = useHistory();

  const defaultFilter = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const [selectedObjects, setSelectedObjects] = useState(undefined);
  const { filters: availableFilters } = useAvailableFilters("records");
  const [filter, setFilter] = useState(defaultFilter);

  // получить уровень выборки, страна, город, адрес, зависит от зума карты
  const getLayer = (zoom) => {
    if (zoom <= 5) {
      return "country";
    }
    if (zoom > 5 && zoom <= 11) {
      return "city";
    }
    return "address";
  };

  const handleOpenResultPage = () => {
    if (selectedObjects === undefined) return;
    // zoom: 5, points: [[59.2898, 36.390], [35.5949, 25.3988]],
    const { zoom, points } = selectedObjects;
    const { path } = routes.results;

    let pointsStr = "";
    for (let i = 0; i < points.length; i++) {
      pointsStr = pointsStr + "," + points[i].join(",");
    }

    // Координаты=city,59.2898,36.390,35.5949,25.3988
    const value = getLayer(zoom) + pointsStr;

    const coordinatesRule = {
      type: "filter",
      uuid: generateUuid(),
      filter: {
        id: '"records"."Coord"',
        isCanFilter: true,
        isCanSorting: false,
        jsonName: "",
        name: "Координаты",
        type: "string",
      },
      condition: "AND",
      value,
    };
    const copyFilter = { ...filter };
    copyFilter.filters.push(coordinatesRule);
    const filterString = JSON.stringify(copyFilter);

    // const url = path + "?filter=" + JSON.stringify([["Координаты", "=", [value]]]) + `&strFilter=${filterString}`;
    const url = path + `?strFilter=${filterString}`;
    history.push(encodeURI(url));
  };

  const postMessageToIframe = useCallback(
    (data) => {
      if (!ref && !ref.current) return;
      ref.current.contentWindow.postMessage(data, src);
    },
    [src]
  );

  const postDrawLayer = useCallback(
    (payload) => {
      const filterString = JSON.stringify(filter);

      if (filter.filters.length === 0) {
        postMessageToIframe({ type: "clearMap" });
      }

      if (filter.filters.length !== 0) {
        const { zoom, viewBox } = payload;
        const { _northEast, _southWest } = viewBox;
        const body = {
          filter: filterString,
          layer: getLayer(zoom),
          northEast: { lat: _northEast.lat, lng: _northEast.lng },
          southWest: { lat: _southWest.lat, lng: _southWest.lng },
        };
        RecordService.getStatLayer(body).then(({ data }) => {
          console.log("[R7]    ПОЛУЧЕНЫ ДАННЫЕ С СЕРВЕРА: ", data);
          const message = {
            type: "drawLayer",
            payload: data,
          };
          postMessageToIframe(message);
        });
      }
    },
    [filter, postMessageToIframe]
  );

  const receiveMessageFromIframe = useCallback(
    (event) => {
      const { data } = event;

      if (data.source && data.source === "tactic-map") {
        console.log("[R7]    ПОЛУЧЕНЫ ДАННЫЕ ОТ КАРТЫ: ", data);

        if (data.type === "getData") {
          postDrawLayer(data.payload);
        }

        if (data.type === "setFilter") {
          setSelectedObjects(data.payload);
        }

        if (data.type === "clearFilter") {
          setSelectedObjects(undefined);
        }
      }
    },
    [postDrawLayer]
  );

  const handleSearch = () => {
    if (filter.filters.length === 0) {
      postMessageToIframe({ type: "clearMap" });
    }

    if (filter.filters.length !== 0) {
      postMessageToIframe({ type: "getViewBox" });
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessageFromIframe);
    return () => {
      window.removeEventListener("message", receiveMessageFromIframe);
    };
  }, [receiveMessageFromIframe]);

  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.filter}>
          <HistoryFilters setFilter={setFilter} />
          <div className={classes.input}>
            <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.button, classes.mr10)}
          onClick={handleSearch}
          size="small"
        >
          Поиск
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleOpenResultPage}
          disabled={selectedObjects === undefined}
          size="small"
        >
          открыть в результатах
        </Button>
      </div>
      <iframe src={src} className={classes.iframe} title="Тактическая карта" ref={ref} />
    </Paper>
  );
};

const Map = () => {
  const classes = useStyles();
  const { services } = useSelector((state) => state.system);

  const serviceMap = services.find((s) => s.id === "map");

  if (serviceMap === undefined) {
    return (
      <div className={classes.noData}>
        <NoData />
      </div>
    );
  }

  return <Iframe src={serviceMap.host} />;
};

export default Map;
