import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useRecords from "hooks/records/useRecords";
import useRecordsCount from "hooks/records/useRecordsCount";
import RecordService from "services/RecordService";
import TaskService from "services/TaskService";
import SpeakerService from "services/SpeakerService";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import { removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { Record, RecordDetail } from "types/record";
import { RootState } from "redux/types";
import { TaskDetailType } from "types/task";
import { Label } from "types/label";
import { Speaker, UserFile } from "types/speaker";
import { AdminTypeFilter } from "types/admin";
import { Group, Rule } from "types/queryBuilder";
import { generateUuid } from "functions/common";
import { setPageRecordsTableSettings } from "redux/actions/pageSettingsActions";
import { subscriber } from "subscribers/RecordStatusSubscriber";
import { catchError } from "functions/common";

// components
import Files from "./files/Files";
import PaginationPanel from "components/pagination/PaginationPanel";
import RecordsTable from "./RecordsTable";
import RemoveLabelDialog from "./RemoveLabelDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateSpeakerFromResultDialog from "./CreateSpeakerFromResultDialog";
import CopyDialog from "./CopyDialog";
import ActionButtons from "./ActionButtons";
import CreateTaskDialog from "pages/tasks/components/CreateTaskDialog";
import CreateExportTaskDialog from "pages/tasks/components/CreateExportTaskDialog";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import SetLabelDialog from "./SetLabelDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setViewed } from "redux/actions/viewedRecordActions";

const useStyles = makeStyles(() => ({
  root: {
    // height: 'calc(-150px + 100vh)',
    // paddingBottom: '5px',
    // marginTop: '90px',
  },
  loading: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pagination: {
    paddingTop: 5,
  },
  files: {
    paddingTop: 5,
  },
}));

interface Props {
  filterString: string;
  setRecordDetail: Function;
  setSelectedRecord: Function;
  recordDetail: RecordDetail | undefined;
  availableFilters: AdminTypeFilter[];
  setFilter: Function;
  height: string;
  tableHeight: string;
}

const Records: FC<Props> = ({
  filterString,
  recordDetail,
  setRecordDetail,
  setSelectedRecord,
  availableFilters,
  setFilter,
  height,
  tableHeight,
}) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rowsPerPage: perPage, sortName, sortOrder, editMode } = useSelector((state: RootState) => state.settings);
  const { isCanDelete } = useSelector((state: RootState) => state.access);

  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openCreateExportTaskDialog, setOpenCreateExportTaskDialog] = useState(false);
  const [openCreateSpeakerDialog, setOpenCreateSpeakerDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openSetLabelDialog, setOpenSetLabelDialog] = useState(false);
  const [openRemoveLabelDialog, setOpenRemoveLabelDialog] = useState(false);
  const [openSetDictionaryDialog, setOpenSetDictionaryDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  // при создании задания из результатов, нужен фильтр
  const [createTaskFilter, setCreateTaskFilter] = useState(defaultFilter);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Record[]>([]);
  const [showRecordFiles, setShowRecordFiles] = useState(false);
  const [offset, setOffset] = useState(0);

  const queryString = useMemo(() => `?filterv2=${filterString}&sortName=${sortName}&sortOrder=${sortOrder}`, [
    filterString,
    sortName,
    sortOrder,
  ]);

  const { records, getRecords, loading, error } = useRecords(offset, perPage, queryString);
  const { count, getCount } = useRecordsCount(queryString);

  const disabledCreateSpeaker = useMemo(() => selectedRows.length !== 1 || recordDetail === undefined, [
    selectedRows,
    recordDetail,
  ]);

  const handleRefresh = () => {
    getRecords().catch(catchError);
    getCount().catch(catchError);
  };

  const handleCloseCreateSpeakerDialog = (data?: { speaker: Speaker; userFiles: UserFile[] }) => {
    setOpenCreateSpeakerDialog(false);
    if (data) {
      const { speaker, userFiles } = data;
      const fd = new FormData();
      fd.append("speaker", JSON.stringify(speaker));
      for (let i = 0; i < userFiles.length; i++) {
        const f = userFiles[i];
        fd.append(f.uuid, f.file);
      }
      SpeakerService.create(fd)
        .then(() => {
          dispatch(showSuccessAlert("Диктор создан"));
        })
        .catch(catchError);
    }
  };

  const handleOpenCreateTaskDialog = () => {
    const ids = selectedRows.map((el) => el.id).join("\n");

    const filter = availableFilters.find((f) => f.jsonName === "id");
    if (filter === undefined) {
      dispatch(showErrorAlert("Фильтр не найден."));
      return;
    }

    const rule: Rule = {
      condition: "=",
      filter: filter.name,
      type: "filter",
      uuid: generateUuid(),
      value: ids,
    };
    setCreateTaskFilter((prev) => {
      prev.filters = [rule];
      return prev;
    });
    setOpenCreateTaskDialog(true);
  };

  const handleOpenCreateExportDialog = () => {
    const ids = selectedRows.map((el) => el.id).join("\n");

    const filter = availableFilters.find((f) => f.jsonName === "id");
    if (filter === undefined) {
      dispatch(showErrorAlert("Фильтр не найден."));
      return;
    }

    const rule: Rule = {
      condition: "=",
      filter: filter.name,
      type: "filter",
      uuid: generateUuid(),
      value: ids,
    };
    setCreateTaskFilter((prev) => {
      prev.filters = [rule];
      return prev;
    });
    setOpenCreateExportTaskDialog(true);
  };

  const handleSetIsViewed = () => {
    if (selectedRows.length === 0) return;

    const ids = selectedRows.map((r) => r.id);
    const value = !selectedRows[0].isViewed;
    RecordService.setViewed(ids, value).then(() => {
      getRecords().catch(catchError);
    });
  };

  const handleCloseCreateTaskDialog = (data?: any) => {
    setOpenCreateTaskDialog(false);
    if (data !== undefined) {
      const { name, comment, isActive, isForce, techs, filter } = data;
      const body: any = {
        name,
        comment,
        isActive,
        taskDetail: {
          type: TaskDetailType.Process,
          filterDetail: filter,
          techDetail: {
            isForce,
            techs,
          },
          exports: data.exports,
        },
      };
      TaskService.create(body)
        .then(() => {
          dispatch(showSuccessAlert("Задание создано и поставлено в очередь обработки."));
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateExportTaskDialog = (data?: any) => {
    setOpenCreateExportTaskDialog(false);
    if (data !== undefined) {
      TaskService.create(data)
        .then(() => {
          dispatch(showSuccessAlert("Задание на экспорт создано."));
        })
        .catch(catchError);
    }
  };

  // установить маркер
  const handleCloseSetLabelDialog = (label?: Label) => {
    setOpenSetLabelDialog(false);
    if (label) {
      RecordService.setLabel(
        label.id,
        selectedRows.map((r) => r.id)
      )
        .then(() => {
          if (gridApi) {
            selectedRows.forEach((r) => {
              const labels = r.labels ?? [];
              const node = gridApi.getRowNode(String(r.id));
              const labelAlreadyExist = () => {
                for (let i = 0; i < labels.length; i++) {
                  if (labels[i].id === label.id) {
                    return true;
                  }
                }
                return false;
              };
              if (node && !labelAlreadyExist()) {
                node.setDataValue("labels", [...labels, label]);
              }
            });
          }
        })
        .catch(catchError);
    }
  };

  // удалить маркер
  const handleCloseRemoveLabelDialog = (label?: Label) => {
    setOpenRemoveLabelDialog(false);
    if (label) {
      RecordService.removeLabel(
        label.id,
        selectedRows.map((r) => r.id)
      )
        .then(() => {
          if (gridApi) {
            selectedRows.forEach((r) => {
              const labels = r.labels ?? [];
              const node = gridApi.getRowNode(String(r.id));
              if (node) {
                node.setDataValue(
                  "labels",
                  labels.filter((l) => l.id !== label.id)
                );
              }
            });
          }
        })
        .catch(catchError);
    }
  };

  const handleCloseCopyDialog = (namespaceId?: number) => {
    setOpenCopyDialog(false);
    if (namespaceId !== undefined) {
      const ids = selectedRows.map((el) => el.id);
      RecordService.copy({ ids, namespaceId })
        .then(() => {
          dispatch(showSuccessAlert("Записи скопированы"));
        })
        .catch(catchError);
    }
  };

  const getUniqueLabels = (): Label[] => {
    let res: Label[] = [];
    const map = new Map();
    for (let i = 0; i < selectedRows.length; i++) {
      const r = selectedRows[i];
      if (r.labels) {
        r.labels.forEach((l) => {
          map.set(l.id, l);
        });
      }
    }
    map.forEach((l) => res.push(l));
    return res;
  };

  const onSelection = () => {
    setRecordDetail(undefined);
    setSelectedRecord(undefined);
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);

      if (rows.length === 1) {
        const record = rows[0];
        setSelectedRecord(record);

        // получить дефолтные данные записи
        RecordService.getDefaultData(String(record.id))
          .then((res) => {
            setRecordDetail(res.data);
          })
          .catch(catchError);
        // установить признак Просмотрено
        if (!record.isViewed) {
          dispatch(setViewed(record.id, true));
          RecordService.setViewed([record.id], true).then(() => {
            try {
              const rowNode = gridApi.getRowNode(String(record.id));
              // rowNode.setDataValue("isViewed", true);
              rowNode.setData({ ...record, isViewed: true });
            } catch (err) {}
          });
        }
      }

      if (rows.length === 0) {
        setShowRecordFiles(false);
      }
    }
  };

  const getPrevRow = useCallback(
    (record: Record) => {
      let prevRec = undefined;
      for (let i = 0; i < records.length; i++) {
        const curr = records[i];
        if (curr.id === record.id && i !== 0) {
          prevRec = records[i - 1];
          break;
        }
      }
      return prevRec;
    },
    [records]
  );

  const getNextRow = useCallback(
    (record: Record) => {
      let nextRec = undefined;
      for (let i = 0; i < records.length; i++) {
        const curr = records[i];
        if (curr.id === record.id && i !== records.length - 1) {
          nextRec = records[i + 1];
          break;
        }
      }
      return nextRec;
    },
    [records]
  );

  const selectRow = useCallback(
    (row: Record) => {
      if (gridApi) {
        const rowNode = gridApi.getRowNode(String(row.id));
        gridApi.deselectAll();
        setShowRecordFiles(false);
        rowNode.setSelected(true);
      }
    },
    [gridApi]
  );

  const selectNextOrPrevRow = useCallback(
    (der: "prev" | "next") => {
      if (gridApi) {
        const rows = gridApi.getSelectedRows();
        if (rows.length > 0) {
          const row = rows[0];
          const nextRow = der === "next" ? getNextRow(row) : getPrevRow(row);
          if (nextRow) selectRow(nextRow);
        }
      }
    },
    [gridApi, getNextRow, getPrevRow, selectRow]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (document.activeElement?.tagName === "INPUT") return;
      if (editMode) return;
      const { key, repeat } = event;

      const preventKeys = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", " "];
      if (preventKeys.includes(key)) event.preventDefault();
      if (key === "ArrowUp" && !repeat) {
        selectNextOrPrevRow("prev");
      }
      if (key === "ArrowDown" && !repeat) {
        selectNextOrPrevRow("next");
      }
    },
    [selectNextOrPrevRow, editMode]
  );

  const handleCloseSetDictionaryDialog = (confirm: boolean) => {
    setOpenSetDictionaryDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id);
      RecordService.setDictionary({ ids })
        .then(({ data }) => {
          dispatch(showSuccessAlert("Сеансы проверены в справочнике"));
          data.forEach((r) => {
            updateRow(r, gridApi);
          });
        })
        .catch(catchError);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id);
      RecordService.removeRecords(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
          getCount().catch(catchError);
        })
        .catch(catchError);
    }
  };

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageRecordsTableSettings(colState));
    }
  };

  useEffect(() => {
    if (records.length === 0) return;
    if (gridApi === undefined) return;
    const ids = records.map((r) => r.id);
    RecordService.getLabels(ids)
      .then((res) => {
        res.data.forEach(({ recordId, labels }) => {
          const node = gridApi.getRowNode(String(recordId));
          if (node) {
            node.setDataValue("labels", labels);
          }
        });
      })
      .catch(catchError);
  }, [records, gridApi]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (error === undefined) return;
    catchError(error);
  }, [error]);

  useEffect(() => {
    setSelectedRows([]);
  }, [records]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  useEffect(() => {
    subscriber.subscribe(({ data }) => {
      if (gridApi === undefined || data === undefined) return;
      gridApi.forEachNode((node) => {
        if (node.id === String(data.id)) {
          node.setData({ ...node.data, ...data });
          return;
        }
      });
    });
  }, [gridApi, setSelectedRecord, setRecordDetail]);

  return (
    <div className={classes.root} style={{ height: height }}>
      <ActionButtons
        availableFilters={availableFilters}
        refresh={handleRefresh}
        selectedRows={selectedRows}
        setOpenSetLabelDialog={setOpenSetLabelDialog}
        setOpenRemoveLabelDialog={setOpenRemoveLabelDialog}
        setShowRecordFiles={setShowRecordFiles}
        setOpenDeleteDialog={setOpenDeleteDialog}
        setOpenCopyDialog={setOpenCopyDialog}
        setOpenSetDictionaryDialog={setOpenSetDictionaryDialog}
        setOpenCreateSpeakerDialog={setOpenCreateSpeakerDialog}
        disabledCreateSpeaker={disabledCreateSpeaker}
        handleSetIsViewed={handleSetIsViewed}
        isCanDelete={isCanDelete}
        handleOpenCreateTaskDialog={handleOpenCreateTaskDialog}
        handleOpenCreateExportDialog={handleOpenCreateExportDialog}
        setOpenSettingsTableDialog={setOpenSettingsTableDialog}
      />

      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <RecordsTable
          setFilter={setFilter}
          availableFilters={availableFilters}
          rowData={records}
          setGridApi={setGridApi}
          setColumnApi={setColumnApi}
          onSelection={onSelection}
          height={tableHeight}
        />
      )}
      <div className={classes.pagination}>
        <PaginationPanel countSelected={selectedRows.length} countTotal={count} setOffset={setOffset} />
      </div>

      {showRecordFiles && selectedRows.length === 1 && (
        <div className={classes.files}>
          <Files recordId={selectedRows[0].id} setRecordDetail={setRecordDetail} />
        </div>
      )}

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <ConfirmationDialog
        open={openSetDictionaryDialog}
        onClose={handleCloseSetDictionaryDialog}
        message="Проверить сеансы в справочнике?"
      />
      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      {selectedRows.length === 1 && recordDetail && (
        <CreateSpeakerFromResultDialog
          recordDetail={recordDetail}
          open={openCreateSpeakerDialog}
          onClose={handleCloseCreateSpeakerDialog}
          record={selectedRows[0]}
        />
      )}

      {selectedRows.length !== 0 && (
        <>
          <CopyDialog open={openCopyDialog} onClose={handleCloseCopyDialog} />
          <CreateTaskDialog
            open={openCreateTaskDialog}
            onClose={handleCloseCreateTaskDialog}
            filterDetail={createTaskFilter}
            isForce={true}
          />
          <CreateExportTaskDialog
            open={openCreateExportTaskDialog}
            onClose={handleCloseCreateExportTaskDialog}
            filterDetail={createTaskFilter}
          />
          <SetLabelDialog open={openSetLabelDialog} onClose={handleCloseSetLabelDialog} />
          <RemoveLabelDialog
            open={openRemoveLabelDialog}
            onClose={handleCloseRemoveLabelDialog}
            labels={getUniqueLabels()}
          />
        </>
      )}
    </div>
  );
};

export default Records;
