import React, { FC, useState } from "react";
import { Keywords as KeywordsType } from "types/record";
import { GridApi } from "ag-grid-community";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import KeywordsTable from "./KeywordsTable";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

interface Props {
  keywords: KeywordsType[];
  loading: boolean;
  height: string;
}

const Keywords: FC<Props> = ({ keywords, loading, height }) => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onClicked = () => {
    if (gridApi === undefined) return;
    const rows = gridApi.getSelectedRows();
    const kw: KeywordsType = rows[0];
    const { start } = kw.words[0];
    currTimeSubscriber.next(start);
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return <KeywordsTable rowData={keywords} setGridApi={setGridApi} onClicked={onClicked} height={height} />;
};

export default Keywords;
