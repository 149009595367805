import { makeStyles, Theme } from "@material-ui/core";
import React, { FC } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  themeColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const LoadingAudio: FC = () => {
  const classes = useStyles();
  return (
    <div className="loading-indicator">
      <div className={clsx(classes.themeColor, "cube")}></div>
      <div className={clsx(classes.themeColor, "cube")}></div>
      <div className={clsx(classes.themeColor, "cube")}></div>
    </div>
  );
};

export default LoadingAudio;
