import { useCallback, useEffect, useState } from "react";
import { Record } from "types";
import { AxiosError } from "axios";
import RecordService from "services/RecordService";
import { useDispatch } from "react-redux";
import { setRecords } from "redux/actions/viewedRecordActions";

interface State {
  loading: boolean;
  records: Record[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  records: [],
  error: undefined,
};

const useRecords = (offset: number, count: number, query: string) => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useDispatch();

  const getRecords = useCallback(async () => {
    setState({ error: undefined, records: [], loading: true });
    RecordService.getAll(offset, count, query)
      .then(({ data }) => {
        setState({ records: data, loading: false, error: undefined });
        //сохраняю в редакс для иконок просмотрено и важное
        dispatch(setRecords(data));
      })
      .catch((err) => {
        setState({ records: [], loading: false, error: err });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, offset, count]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return { ...state, getRecords };
};

export default useRecords;
