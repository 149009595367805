import React, { ChangeEvent, FC, useEffect, useState } from "react";
import ExternalApiService from "services/admin/ExternalApiService";
import { catchError } from "functions/common";
import SelectNamespace from "components/SelectNamespace";
import clsx from "clsx";

// material ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Skeleton from "@material-ui/lab/Skeleton";
import { grey, red, teal } from "@material-ui/core/colors";
import { Button, Grid, TextField } from "@material-ui/core";
import { Config } from "types/externalApi";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 96px)",
    overflow: "auto",
    padding: theme.spacing(1),
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  switch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginTop: 20,
    marginBottom: 50,
  },
  title: {
    marginLeft: 10,
  },
  block: {
    width: 800,
    marginBottom: theme.spacing(2),
  },
  up: {
    backgroundColor: theme.palette.primary.main,
  },
  down: {
    backgroundColor: theme.palette.text.disabled,
  },
  namespace: {
    marginTop: theme.spacing(2),
  },
  c: {
    padding: theme.spacing(3),
  },
  img: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  input: {
    display: "none",
  },
  w: {
    height: "calc(100vh - 755px)",
    overflow: "auto",
    border: "1px solid " + grey[400],
    padding: 5,
    backgroundColor: "#303030",
    color: "#f7f7f7",
    margin: theme.spacing(1),
  },
  pre: {
    whiteSpace: "break-spaces",
  },
  testingSection: {
    padding: theme.spacing(1),
  },
  ml16: {
    marginLeft: theme.spacing(2),
  },
  mt8: {
    marginTop: theme.spacing(1),
  },
  autocomplete: {
    width: 642,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface State {
  loading: boolean;
  setActive: boolean;
  namespaceId: number;
  file: File | null;
  fileId: string;
  result: string;
}

const CustomSwitch = withStyles({
  switchBase: {
    color: red[500],
    "& + $track": {
      backgroundColor: red[300],
      opacity: 0.75,
      border: "none",
    },
    "&$checked": {
      color: teal[500],
    },
    "&$checked + $track": {
      backgroundColor: teal[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const ExternalApi: FC = () => {
  const classes = useStyles();

  const [state, setState] = useState<State>({
    loading: false,
    setActive: false,
    namespaceId: 0,
    file: null,
    fileId: "",
    result: "",
  });

  const { loading, setActive, namespaceId, file, fileId, result } = state;

  const codecList = [
    "wav",
    "mp3",
    "mka",
    "ambe.dmr",
    "comm.dmr",
    "nvoc.dmr",
    "selp0.dmr",
    "selp1.dmr",
    "gsm",
    "gsm_abis",
    "umts_amr",
    "umts.amr-wb",
    "evs",
    "g728",
    "g.729",
  ];

  const [config, setConfig] = useState<Config>({
    codec: "wav",
    techs: [
      {
        type: "stt",
        parameters: {
          isActive: true,
          language: "russian",
          numeral: "text",
        },
      },
      {
        type: "lid",
        parameters: {
          isActive: true,
        },
      },
      {
        type: "vad",
        parameters: {
          isActive: true,
          detector: "default",
        },
      },
    ],
  });

  const onChange = (event: any) => {
    const { checked } = event.target;
    const body = { setActive: checked, namespaceId };
    ExternalApiService.setState(body)
      .then(() => {
        setState((prev) => ({ ...prev, ...body }));
      })
      .catch(catchError);
  };

  const setNamespaceId = (id: number) => {
    const body = { setActive: setActive, namespaceId: id };
    ExternalApiService.setState(body)
      .then(() => {
        setState((prev) => ({ ...prev, ...body }));
      })
      .catch(catchError);
  };

  const uploadFile = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("audio", file);
    formData.append("config", JSON.stringify(config));
    // console.log("config", JSON.stringify(config))

    ExternalApiService.uploadFile(formData)
      .then(({ data }) => {
        setState((prev) => ({ ...prev, fileId: data.message }));
      })
      .catch(console.error);
  };

  const getResult = () => {
    if (!fileId) return;

    ExternalApiService.getResultById(fileId)
      .then(({ data }) => {
        setState((prev) => ({ ...prev, result: JSON.stringify(data, null, 2) }));
      })
      .catch(console.error);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setState((prev) => ({ ...prev, file: selectedFile }));
  };

  const handleChangeCodec = (event: any, value: any) => {
    setConfig((prev) => ({ ...prev, codec: value }));
  };

  const handleChangeId = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    ExternalApiService.getState()
      .then(({ data }) => {
        const { namespaceId, setActive } = data;
        setState((prev) => ({ ...prev, loading: false, namespaceId, setActive }));
      })
      .catch(catchError)
      .finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  }, []);

  if (loading) return null;

  return (
    <Paper className={classes.root}>
      <div className={classes.block}>
        {loading ? (
          <Skeleton variant="rect" width={400} height={230} />
        ) : (
          <div className={classes.block}>
            <div className={classes.c}>
              <div className={classes.switch}>
                <Typography className={classes.title}>Выкл</Typography>
                <CustomSwitch edge="end" onChange={onChange} checked={setActive} />
                <Typography className={classes.title}>Вкл</Typography>
              </div>
              <Typography variant="h6">Пространство для сохранения:</Typography>
              <div className={classes.namespace}>
                <SelectNamespace namespaceId={namespaceId} setNamespaceId={setNamespaceId} size="medium" />
              </div>
            </div>
          </div>
        )}
      </div>

      <Grid container spacing={2} className={classes.testingSection}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.ml16}>
            Проверка:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <input
            accept="*"
            onChange={handleFileChange}
            className={classes.input}
            id="contained-button-file"
            multiple={false}
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span" className={classes.ml16}>
              Выбрать файл
            </Button>
          </label>
          {file && <Typography className={clsx(classes.ml16, classes.mt8)}>{file.name}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            size="small"
            options={codecList}
            defaultValue={"wav"}
            onChange={handleChangeCodec}
            renderInput={(params) => <TextField {...params} label="Кодек" size="small" />}
            className={classes.autocomplete}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={uploadFile} disabled={!file} className={classes.ml16}>
            Отправить файл
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={fileId}
                onChange={handleChangeId}
                name="fileId"
                label="Id записи"
                style={{ width: 642 }}
                className={classes.ml16}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={getResult}
                disabled={!fileId}
                className={clsx(classes.ml16, classes.mt8)}
              >
                Получить результаты
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.w}>
                <pre className={classes.pre}>{result}</pre>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExternalApi;
