import { Record } from "types";

export const SET_VIEWED = "SET_VIEWED";
export const SET_FAVORITE = "SET_FAVORITE";
export const SET_RECORDS = "SET_RECORDS";

export interface RecordsState {
  records: Record[];
}

interface SetViewedAction {
  type: typeof SET_VIEWED;
  payload: { id: number; isViewed: boolean };
}

interface SetFavoriteAction {
  type: typeof SET_FAVORITE;
  payload: { id: number; isFavorite: boolean };
}

interface SetRecordsAction {
  type: typeof SET_RECORDS;
  payload: Record[];
}

export type RecordsActionTypes = SetViewedAction | SetFavoriteAction | SetRecordsAction;
