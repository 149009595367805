import React, { FC } from "react";
import { Visibility, Star, StarBorder } from "@material-ui/icons";
import RecordService from "services/RecordService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setFavorite, setViewed } from "redux/actions/viewedRecordActions";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  activeIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  inactiveIcon: {
    cursor: "pointer",
    color: "grey",
  },
  viewedIcon: {
    marginLeft: 8,
  },
}));

interface ViewIconRendererProps {
  data: any;
  api: any;
}

const ViewIconRenderer: FC<ViewIconRendererProps> = ({ data, api }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const record = useSelector((state: RootState) => state.viewedRecord.records.find((r) => r.id === data.id));

  const handleIsViewedClick = async () => {
    if (!record) return;
    const newIsViewed = !record.isViewed;
    try {
      dispatch(setViewed(data.id, newIsViewed));

      await RecordService.setViewed([data.id], newIsViewed);

      api.applyTransaction({
        update: [{ ...data, isViewed: newIsViewed }],
      });
    } catch (error) {
      console.error("Ошибка обновления статуса", error);
    }
  };

  const handleIsFavoriteClick = async () => {
    if (!record) return;
    const newIsFavorite = !record.isFavorite;
    try {
      // Если запись ещё не просмотрена, изменяем её статус на "Просмотрено"
      if (!record.isViewed) {
        await handleIsViewedClick();
      }

      dispatch(setFavorite(data.id, newIsFavorite));

      api.applyTransaction({
        update: [{ ...data, isFavorite: newIsFavorite }],
      });
    } catch (error) {
      console.error("Ошибка обновления статуса", error);
    }
  };

  return (
    <div className={classes.iconContainer}>
      {record?.isFavorite ? (
        <Star
          onClick={(e) => {
            e.stopPropagation();
            handleIsFavoriteClick();
          }}
          className={classes.activeIcon}
          color="primary"
          titleAccess="Важное"
        />
      ) : (
        <StarBorder
          onClick={(e) => {
            e.stopPropagation();
            handleIsFavoriteClick();
          }}
          className={classes.inactiveIcon}
          titleAccess="Отметить как важное"
        />
      )}

      {record?.isViewed ? (
        <Visibility
          onClick={(e) => {
            e.stopPropagation();
            handleIsViewedClick();
          }}
          className={`${classes.activeIcon} ${classes.viewedIcon}`}
          color="primary"
          titleAccess="Просмотрено"
        />
      ) : (
        <Visibility
          onClick={(e) => {
            e.stopPropagation();
            handleIsViewedClick();
          }}
          className={`${classes.inactiveIcon} ${classes.viewedIcon}`}
          titleAccess="Не просмотрено"
        />
      )}
    </div>
  );
};

export default ViewIconRenderer;
