import { admin } from "http/urls";
import api from "http/api";
import { AxiosResponse } from "axios";

const { externalApi: route } = admin;

function ExternalApiService() {
  function getState(): Promise<AxiosResponse<{ setActive: boolean; namespaceId: number }>> {
    return api.get(route.setStatus);
  }

  function setState(body: { setActive: boolean; namespaceId: number }) {
    return api.post(route.setStatus, body);
  }

  function currentVersion() {
    return api.get(route.getVersion);
  }

  function uploadFile(formData: FormData): Promise<AxiosResponse<any>> {
    return api.post(route.uploadFile, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  function getResultById(id: string): Promise<AxiosResponse<any>> {
    const url = route.getFileResult.replace(":id", id);
    return api.get(url);
  }

  return Object.freeze({
    getState,
    setState,
    currentVersion,
    uploadFile,
    getResultById,
  });
}

export default ExternalApiService();
