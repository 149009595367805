import React, { useState, MouseEvent, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { Code } from "@material-ui/icons";
import { RecordFileViewType } from "types";

const useStyles = makeStyles(() => ({
  button: {
    minWidth: "44px",
  },
  icon: {
    marginRight: 0,
  },
}));

interface ViewTypeButtonProps {
  onViewTypeChange: (viewType: RecordFileViewType) => void;
  currentViewType: RecordFileViewType;
}

const ViewTypeButton: FC<ViewTypeButtonProps> = ({ onViewTypeChange, currentViewType }) => {
  const classes = useStyles();
  const [viewType, setViewType] = useState<RecordFileViewType>(RecordFileViewType.AUDIO);

  const handleChangeView = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    let newViewType: RecordFileViewType;

    switch (viewType) {
      case RecordFileViewType.AUDIO:
        newViewType = RecordFileViewType.VIDEO;
        break;
      case RecordFileViewType.VIDEO:
        newViewType = RecordFileViewType.TEXT;
        break;
      case RecordFileViewType.TEXT:
        newViewType = RecordFileViewType.BITS;
        break;
      case RecordFileViewType.BITS:
        newViewType = RecordFileViewType.AUDIO;
        break;
      default:
        newViewType = RecordFileViewType.AUDIO;
    }
    setViewType(newViewType);
    onViewTypeChange(newViewType);
  };

  const getViewTypeName = (type: RecordFileViewType) => {
    switch (type) {
      case RecordFileViewType.AUDIO:
        return "Аудио";
      case RecordFileViewType.VIDEO:
        return "Видео";
      case RecordFileViewType.TEXT:
        return "Текст";
      case RecordFileViewType.BITS:
        return "Бинарный (текст)";
      default:
        return "";
    }
  };

  const renderIcon = () => {
    switch (viewType) {
      case RecordFileViewType.AUDIO:
        return <AudiotrackIcon className={classes.icon} />;
      case RecordFileViewType.VIDEO:
        return <YouTubeIcon className={classes.icon} />;
      case RecordFileViewType.TEXT:
        return <TextFieldsIcon className={classes.icon} />;
      case RecordFileViewType.BITS:
        return <Code className={classes.icon} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setViewType(currentViewType);
  }, [currentViewType]);

  return (
    <span
      title={`Текущий тип отображения: ${getViewTypeName(viewType)}. Нажмите чтобы изменить.`}
      style={{ marginLeft: "10px" }}
    >
      <Button variant="outlined" size="small" onClick={handleChangeView} className={classes.button} color="primary">
        {renderIcon()}
      </Button>
    </span>
  );
};

export default ViewTypeButton;
