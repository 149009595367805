import { PLAYER_EXPAND, PLAYER_COLLAPSE, AudioPlayerAction, AudioPlayerState } from "redux/types/audioPlayerTypes";
import { RootState } from "redux/types";
import { load } from "redux-localstorage-simple";

// получить сохраненный store из localstorage
const storageState: RootState = load({ namespace: "PLAYER" }) as RootState;

const defaultState: AudioPlayerState = {
  isExpanded: false,
};

const initialState: AudioPlayerState = storageState.audioPlayer ?? defaultState;

export default (state = initialState, action: AudioPlayerAction) => {
  const { type } = action;

  if (type === PLAYER_EXPAND) {
    return {
      isExpanded: true,
    };
  }

  if (type === PLAYER_COLLAPSE) {
    return {
      isExpanded: false,
    };
  }

  return state;
};
