import React, { useCallback, useState, useMemo } from "react";
import { generateUuid } from "functions/common";
import { Group } from "../../types/queryBuilder";
import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import HistoryFilters from "components/search/HistoryFilters";
import Filter from "components/filter/Filter";
import Graph from "./Graph";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    position: "relative",
  },
  paperWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input2: {
    flexGrow: 1,
    marginRight: 15,
  },
  select: {
    width: "200px",
    marginRight: "1rem",
  },
  selectWrapper: {
    display: "flex",
    gap: "1rem",
    alignItems: "end",
  },
}));

// const initialState = {
//   graphNodes: [],
//   graphEdges: [],
// };

const GraphWrapper = () => {
  const classes = useStyles();
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const { filters: availableFilters } = useAvailableFilters("records");
  const [filter, setFilter] = useState<Group>(defaultFilter);
  const [body, setBody] = useState<any>();
  // const [groupBy, setGroupBy] = useState<string>("[name] -- [speaker]");
  const groupBy = "[speaker]";
  // const availableGroupBy = [
  //   "[id]",
  //   "[date]",
  //   "[time]",
  //   "[name]",
  //   "[ext]",
  //   "[terminalIp]",
  //   "[terminalNumber]",
  //   "[terminalName]",
  //   "[lang]",
  //   "[speaker]",
  //   "[gender]",
  //   "[codec]",
  //   "[namespace]",
  //   "[city]",
  //   "[country]",
  //   "[address]",
  // ];

  const handleSearch = useCallback(() => {
    const searchStr = JSON.stringify(filter);
    const body = {
      filter: searchStr,
      clusterBy: groupBy,
    };
    setBody(body);
  }, [filter]);

  // const handleGroupByChange = (event: ChangeEvent<{ value: unknown }>) => {
  //   setGroupBy(event.target.value as string);
  // };

  return (
    <div className={classes.root}>
      <Paper className={classes.paperWrapper} style={{ width: "100%" }}>
        <div className={classes.search}>
          <HistoryFilters setFilter={setFilter} />

          <div className={classes.input2}>
            <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
          </div>
          {/* <div className={classes.selectWrapper}>
            <Typography>Сортировать по:</Typography>
            <Select value={groupBy} onChange={handleGroupByChange} className={classes.select}>
              {availableGroupBy.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </div> */}

          <Button variant="contained" color="primary" onClick={handleSearch}>
            Построить
          </Button>
        </div>
      </Paper>
      <div style={{ width: "100%" }}>
        <Graph data={body} />
      </div>
    </div>
  );
};

export default GraphWrapper;
