import { Record } from "types";
import { SET_VIEWED, SET_FAVORITE, SET_RECORDS, RecordsActionTypes } from "../types/viewedRecordTypes";

// Экшен для установки признака "Просмотрено"
export const setViewed = (id: number, isViewed: boolean): RecordsActionTypes => ({
  type: SET_VIEWED,
  payload: { id, isViewed },
});

// Экшен для установки признака "Избранное"
export const setFavorite = (id: number, isFavorite: boolean): RecordsActionTypes => ({
  type: SET_FAVORITE,
  payload: { id, isFavorite },
});

// Экшен для установки всех записей
export const setRecords = (records: Record[]): RecordsActionTypes => ({
  type: SET_RECORDS,
  payload: records,
});
