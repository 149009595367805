import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "redux/actions/alertActions";
import { Message as TMessage, Order } from "types/message";
import MessageService from "services/MessageService";
import { subscriber } from "subscribers/MessageSubscriber";

// components
import NoData from "components/NoData";
import Message from "./Message";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflowX: "hidden",
    width: "30vw",
  },
  buttons: {
    textAlign: "right",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid " + grey[300],
  },
  messages: {
    height: "calc(100vh - 65px)",
    overflow: "auto",
  },
  noData: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nd: {
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const MessagesList: FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [prevMessage, setPrevMessage] = useState<{ loading: boolean; has: boolean }>({ loading: false, has: true });
  const [nextMessage, setNextMessage] = useState<{ loading: boolean; has: boolean }>({ loading: false, has: true });

  const { loading: isLoadingPrevMessages, has: isHasPrevMessages } = prevMessage;
  const { loading: isLoadingNextMessages, has: isHasNextMessages } = nextMessage;

  const [messages, setMessages] = useState<TMessage[]>([]);
  const [scrollTo, setScrollTo] = useState<TMessage | undefined>(undefined);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  // отметить все как прочитанные
  const handleSetViewedAll = () => {
    MessageService.setViewedAll()
      .then(() => {
        setMessages((prev) => prev.map((m) => ({ ...m, isViewed: true })));
      })
      .catch((err) => catchError(err.response.data));
  };

  // получить сообщения
  const getMessages = (startId: number, order: Order) => MessageService.getByOrder(startId, order);

  // получить предыдущие сообщения
  const getPrevMessages = useCallback(() => {
    if (messages.length === 0) return;
    const startId = messages[0].id;
    const order = "desc";

    setPrevMessage((prev) => ({ ...prev, loading: true }));
    getMessages(startId, order)
      .then(({ data }) => {
        if (data.length === 0) {
          setPrevMessage((prev) => ({ ...prev, has: false }));
          return;
        }
        setMessages((prev) => [...data, ...prev]);
      })
      .catch((err) => catchError(err.response.data))
      .finally(() => {
        setPrevMessage((prev) => ({ ...prev, loading: false }));
      });
  }, [catchError, messages]);

  // получить следующие сообщения
  const getNextMessages = useCallback(() => {
    if (messages.length === 0) return;
    const startId = messages[messages.length - 1].id;
    const order = "asc";

    setNextMessage((prev) => ({ ...prev, loading: true }));
    getMessages(startId, order)
      .then(({ data }) => {
        if (data.length === 0) {
          setNextMessage((prev) => ({ ...prev, has: false }));
          return;
        }
        setMessages((prev) => [...prev, ...data]);
      })
      .catch((err) => catchError(err.response.data))
      .finally(() => {
        setNextMessage((prev) => ({ ...prev, loading: false }));
      });
  }, [catchError, messages]);

  const handleScroll = useCallback(
    (event: any) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop <= clientHeight * 2 && !isLoadingPrevMessages && isHasPrevMessages) {
        getPrevMessages();
      }
      if (scrollTop + clientHeight >= scrollHeight - clientHeight && !isLoadingNextMessages && isHasNextMessages) {
        getNextMessages();
      }
    },
    [
      getPrevMessages,
      getNextMessages,
      isHasPrevMessages,
      isHasNextMessages,
      isLoadingPrevMessages,
      isLoadingNextMessages,
    ]
  );

  useEffect(() => {
    subscriber.subscribe((wsData) => {
      const { message } = wsData.data;
      if (message !== undefined) {
        setMessages((prev) => [...prev, message]);
      }
    });
  }, []);

  useEffect(() => {
    setNextMessage((prev) => ({ ...prev, has: true }));
    if (!open) {
      MessageService.getDefault()
        .then(({ data }) => {
          setMessages(data);
          let scrollTo;
          for (let i = 0; i < data.length; i++) {
            if (!data[i].isViewed) {
              scrollTo = data[i];
              break;
            }
          }
          setScrollTo(scrollTo);
        })
        .catch((err) => catchError(err.response.data));
    }
  }, [open, catchError]);

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSetViewedAll}
            disabled={messages.length === 0 || messages.every((m) => m.isViewed)}
          >
            Отметить все как прочитанные
          </Button>
        </div>

        <div className={classes.messages} onScroll={handleScroll}>
          {messages.length === 0 && (
            <div className={classes.noData}>
              <NoData />
            </div>
          )}
          {messages.map((m) => (
            <Message
              key={m.id}
              m={m}
              scrollToId={scrollTo ? scrollTo.id : messages.length > 0 ? messages[messages.length - 1].id : undefined}
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default MessagesList;
