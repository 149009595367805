import React, { useState, ChangeEvent, FC, ReactNode } from "react";
import { Record, RecordDetail, RecordFileViewType } from "types/record";

// components
import TabTechnologies from "./tabTechnologies/TabTechnologies";
import TabStt from "./tabSTT/TabSTT";
// import TabComment from "./tabComment/TabComment";
// import TabWordsCloud from "./tabWrodsCloud/TabWordsCloud";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import TabKeywords from "./tabKeywords/TabKeywords";

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`result-tabpanel-${index}`}
      aria-labelledby={`result-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `result-tab-${index}`,
    "aria-controls": `result-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  viewTabButtons: {
    flexGrow: 1,
  },
  viewButtons: {
    marginRight: 15,
  },
  history: {
    width: "100%",
    border: "none",
    // height: "calc(100vh - 268px)",
    padding: theme.spacing(2),
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 183px)",
  },
}));

interface Props {
  recordDetail: RecordDetail | undefined;
  selectedRecord: Record | undefined;
  filterString: string;
  height: string;
  sttHeight: string;
  keywordsHeight: string;
  podrobnoTableHeight: string;
  viewType: RecordFileViewType;
}

const ResultDetail: FC<Props> = ({
  recordDetail,
  selectedRecord,
  filterString,
  height,
  keywordsHeight,
  sttHeight,
  podrobnoTableHeight,
  viewType,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (recordDetail === undefined || selectedRecord === undefined) {
    return (
      <div className={classes.root} style={{ height: height }}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            <Tab label="Просмотр" {...a11yProps(0)} />
            {/* <Tab label="Облако слов" {...a11yProps(1)} />
            <Tab label="Комментарий" {...a11yProps(2)} /> */}
            <Tab label="Ключевые слова" {...a11yProps(1)} />
            <Tab label="Подробно" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <div className={classes.noData}>
            <NoData />
          </div>
        </TabPanel>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Просмотр" {...a11yProps(0)} />
          {/* <Tab label="Облако слов" {...a11yProps(1)} />
          <Tab label="Комментарий" {...a11yProps(2)} /> */}
          <Tab label="Ключевые слова" {...a11yProps(1)} />
          <Tab label="Подробно" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <TabStt
          recordDetail={recordDetail}
          selectedRecord={selectedRecord}
          filterv2={filterString}
          height={height}
          keywordsHeight={keywordsHeight}
          sttHeight={sttHeight}
          viewType={viewType}
        />
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        <TabWordsCloud selectedRecord={selectedRecord} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <TabComment selectedRecord={selectedRecord} />
      </TabPanel> */}

      <TabPanel value={value} index={2}>
        <TabTechnologies record={selectedRecord} podrobnoTableHeight={podrobnoTableHeight} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {selectedRecord ? (
          <TabKeywords selectedRecord={selectedRecord} filterv2={filterString} height={sttHeight} />
        ) : (
          <NoData />
        )}
      </TabPanel>
    </div>
  );
};

export default ResultDetail;
