import React, { FC, useMemo } from "react";
import SuperPlayer from "./SuperPlayer";
import { API_URL, records } from "http/urls";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { Record, RecordDetail, RecordFileViewType } from "types";
import useKeywords from "hooks/records/useKeywords";

interface PlayerWrapper {
  recordDetail: RecordDetail;
  record: Record;
  filterv2: string;
  expand: boolean;
  setExpand: Function;
  // viewType: RecordFileViewType;
}

const SuperPlayerWrapper: FC<PlayerWrapper> = ({ recordDetail, record, filterv2, expand, setExpand }) => {
  const { filter } = recordDetail;
  const viewType: RecordFileViewType = RecordFileViewType.AUDIO;

  const { accessToken } = useSelector((state: RootState) => state.auth);
  const {
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerChannel,
    playerGain,
    isShowLayoutVad,
  } = useSelector((state: RootState) => state.settings);

  const audioSrc = useMemo(() => {
    const url = API_URL + records.getFileData;
    const isPan = playerIsPan === undefined ? 0 : playerIsPan;
    const isAdeClick = playerIsAdeClick === undefined ? 0 : playerIsAdeClick;
    const isKTCH = playerIsKTCH === undefined ? 0 : playerIsKTCH;
    const isNoise = playerIsNoise === undefined ? 0 : playerIsNoise;
    const isEcho = playerIsEcho === undefined ? 0 : playerIsEcho;
    const channel = playerChannel === undefined ? -1 : playerChannel;
    const gain = playerGain === undefined ? 1 : playerGain;
    const layoutVad = isShowLayoutVad === undefined ? 1 : isShowLayoutVad;
    const playerSettings = `&isPan=${isPan}&isAdeClick=${isAdeClick}&isNoise=${isNoise}&channel=${channel}&volume=${gain}&isKTCH=${isKTCH}&isEcho=${isEcho}&isShowLayoutVad=${layoutVad}`;
    return decodeURI(
      url.replace(":viewType", viewType) + `?q=${filter}` + playerSettings + `&authorization=${accessToken}`
    );
  }, [
    filter,
    viewType,
    accessToken,
    playerIsPan,
    playerIsAdeClick,
    playerIsKTCH,
    playerIsEcho,
    playerIsNoise,
    playerChannel,
    playerGain,
    isShowLayoutVad,
  ]);

  // query string для ключевых слов
  const keywordsQueryString = useMemo(() => `?filterv2=${filterv2}&channel=${playerChannel}`, [
    playerChannel,
    filterv2,
  ]);
  // ключевые слова
  const { keywords, loading: loadingKeywords } = useKeywords(record.id, keywordsQueryString);

  return (
    <div>
      {recordDetail && !loadingKeywords && (
        <SuperPlayer
          audioUrl={audioSrc}
          markers={keywords}
          fileName={record.name}
          channelNumber={record.channelCount}
          isExpanded={expand}
          setIsExpanded={setExpand}
        />
      )}
    </div>
  );
};

export default SuperPlayerWrapper;
