import React, { FC } from "react";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import ColorRender from "components/agGrid/renderers/ColorRenderer";
import WordsRenderer from "components/agGrid/renderers/WordsRenderer";
import ContextRenderer from "components/agGrid/renderers/ContextRenderer";
import KeyWordTimeRender from "components/agGrid/renderers/KeyWordTimeRenderer";
import { Keywords, Word } from "types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 501px)",
    transition: "height 0.3s ease",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
  whiteSpace: "normal",
  cellClass: "centeredText",
};

const rowHeight = 50;

const columnDefs = [
  {
    headerName: "Слова",
    field: "words",
    width: 100,
    cellRenderer: "WordsRenderer",
    comparator: (valueA: Word[], valueB: Word[]) => {
      const wordsA = valueA.map((w) => w.word).join(" ");
      const wordsB = valueB.map((w) => w.word).join(" ");
      if (wordsA === wordsB) return 0;
      return wordsA > wordsB ? 1 : -1;
    },
  },
  {
    headerName: "Контекст",
    field: "context",
    cellRenderer: "ContextRenderer",
  },
  {
    headerName: "Группа",
    field: "group",
  },
  {
    headerName: "Подгруппа",
    field: "name",
  },
  {
    headerName: "Цвет",
    field: "color",
    cellRenderer: "ColorRender",
    width: 100,
  },
  {
    headerName: "Время",
    field: "words",
    cellRenderer: "KeyWordTimeRender",
    comparator: (valueA: Word[], valueB: Word[]) => {
      const startA = valueA.length > 0 ? valueA[0].start : 0;
      const startB = valueB.length > 0 ? valueB[0].start : 0;
      return startA - startB;
    },
  },
];

interface Props {
  rowData: Keywords[];
  setGridApi: Function;
  onClicked: Function;
  height: string;
}

const KeywordsTable: FC<Props> = ({ rowData, setGridApi, onClicked, height }) => {
  const classes = useStyles();
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <div className={clsx("ag-theme-balham", classes.root)} style={{ height: height, fontSize: "14px" }}>
      <AgGridReact
        rowHeight={rowHeight}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="single"
        onRowClicked={() => onClicked()}
        suppressCopyRowsToClipboard
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          DateRenderer,
          ColorRender,
          WordsRenderer,
          ContextRenderer,
          KeyWordTimeRender,
        }}
      />
    </div>
  );
};

export default KeywordsTable;
