import { Label } from "./label";
import { ProcessStatus } from "./common";

export interface Record {
  id: number;
  labels: Label[];
  namespaceId: number;
  namespaceName: string;
  name: string;
  dateCreated: string;
  dateChanged: string;
  dateRecord: string;
  srcIpAddress: string;
  dstIpAddress: string;
  srcPort: number;
  dstPort: number;
  srcNumber: number;
  dstNumber: number;
  srcName: string;
  dstName: string;
  durationRecord: string;
  realDurationRecord: string;
  srcDurationSpeech: number;
  dstDurationSpeech: number;
  isVad: boolean;
  message: string;
  channelCount: number;
  isNeedProcess: boolean;
  isViewed: boolean;
  isFavorite?: boolean;
  isStt: boolean;
  wordCount: number;
  sttConfidence: number;
  isLid: boolean;
  lang: string;
  isGid: boolean;
  dstGender: Gender;
  srcGender: Gender;
  isQuality: boolean;
  srcQuality: number;
  dstQuality: number;
  isAge: boolean;
  srcAge: string;
  dstAge: string;
  isReverb: boolean;
  srcReverb: string;
  dstReverb: null;
  isDiarization: boolean;
  srcDiarizationCount: number;
  dstDiarizationCount: number;
  isTranslate: boolean;
  translateWordCount: number;
  translateConfidence: number;
  isSid: boolean;
  srcSpeakersId: number[];
  dstSpeakersId: number[];
  srcSpeakerName: string[];
  dstSpeakerName: string[];
  isFax: boolean;
  faxCount: number;
  isFaxExist: number;
  srcIpAddressLocation: string;
  dstIpAddressLocation: string;
  srcNumberLocation: string;
  dstNumberLocation: string;
  wordRating: number;
  isLidSegment: boolean;
  isLidChange: boolean;
  srcMac: string;
  dstMac: string;
  srcMacName: string;
  dstMacName: string;
  srcCountry: string;
  srcCity: string;
  srcAddress: string;
  dstCountry: string;
  dstCity: string;
  dstAddress: string;
  isSnr: boolean;
  srcSnr: string;
  dstSnr: string;
  srcAutoinformatorId: string;
  srcAutoinformatorName: string;
  dstAutoinformatorId: string;
  dstAutoinformatorName: string;
  status: ProcessStatus;
  nameKps: string;
  iDirectChan: string;
  iDirectAdr1: string;
  skinnyAddr: string;
  skinnyChan: string;
  docUuid: number;
  docAlias: string;
  docAuthor: string;
}

export interface RecordDetail {
  viewType: RecordFileViewType;
  filter: string;
  format: string;
}

export interface RecordFile {
  id: number;
  name: string;
  codec: string;
  viewType: RecordFileViewType;
  recordId: number;
  storageFileId: number;
  isNeedProcess: boolean;
  isExistCache: boolean;
}

export enum RecordFileViewType {
  AUDIO = "audio",
  VIDEO = "video",
  TEXT = "text",
  BITS = "bits",
  IMAGE = "image",
  UNKNOWN = "",
}

export type Gender = "male" | "female";

export enum DownloadTextType {
  CLEAR_TEXT = "clearText",
  TEXT = "text",
  TRANSLATE = "translate",
  TEXT_AND_TRANSLATE = "textAndTranslate",
  CTM = "ctm",
  SPEECH_ANNOTATION = "speechAnnotation",
}

export interface Segment {
  id: number;
  recordId: number;
  channelNumber: number;
  lang: string;
  start: number;
  end: number;
  words: Word[];
  tWords: Word[];
  diarizationName: string;
}

export interface Word {
  id: number;
  recordId: number;
  channelNumber: number;
  lang: string;
  word: string;
  confidence: number;
  start: number;
  end: number;
  color: string;
  bgColor: string;
  type: string;
}

export interface WaveformWord extends Word {
  stack: Word[];
  left: number;
}

export interface Keywords {
  group: string;
  name: string;
  words: Word[];
  context: string;
  color: string;
  bgColor: string;
}

export interface DetailLID {
  id: number;
  recordId: number;
  channelNumber: number;
  lang: string;
  confidence: number;
}

export interface LIDSegment {
  id: number;
  recordId: number;
  channelNumber: number;
  lang: string;
  start: number;
  end: number;
  confidence: number;
}

export interface DetailVAD {
  id: number;
  recordId: number;
  channelNumber: number;
  type: string;
  start: number;
  end: number;
}

export interface DetailDiarization {
  id: number;
  recordId: number;
  channelNumber: number;
  speakerId: string;
  start: number;
  end: number;
}

export interface DetailSID {
  id: number;
  recordId: number;
  channelNumber: number;
  speakerId: number;
  speakerName: string;
  confidence: number;
}

export interface DetailFax {
  id: number;
  recordId: number;
  storageFileId: number;
}

export interface DetailSTT {
  id: number;
  recordId: number;
  channelNumber: number;
  lang: string;
  word: string;
  confidence: number;
  color: string;
  bgColor: string;
}

export interface WordsCloudWord {
  word: string;
  confidence: number;
  size: number;
}

export interface AnalyticElement {
  name: string;
  value: string;
  isActive?: boolean;
}

export interface AnalyticTimelineElement {
  date: string;
  count: number;
}

export interface Object {
  id: number;
  parentId: number;
  groupId: string;
  name: string;
  comment: string;
  color: string;
  filter: string;
  clusterBy: string;
  dateChanged: string;
  dateCreated: string;
}
